@import "../lib/bootstrap/scss/bootstrap";

/**FONTS**/
@import "fonts";

/**VARIABLES**/
@import "variables";


body {
  font-family: 'Hind', sans-serif;
  font-size: 0.85rem;
  color: #31454c;
  position: relative;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  @include media-breakpoint-up(md) {
    font-size: 0.95rem;
  }
}

h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 3.8rem;
  font-weight: 700;
  letter-spacing: 10px;
    &:after {
      content: '';
      background-color: #fff;
      width: 60px;
      height: 3px;
      display: block;
      position: relative;
      top: 1rem;
      margin: 0 auto;
    }
}

.img-reveal {
  visibility: hidden;
}


.btn {
  background-color: #fff;
  color: $brand-secondary !important;
  border: 2px solid $brand-primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  border-radius: 0;
  position: relative;
  text-decoration: inherit;
  z-index: 10;
  padding: 16px 28px;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
    &:before {
      content: '+';
      font-weight: 700;
      font-size: 2.4rem;
      color: $brand-primary;
      position: absolute;
      left: -0.75rem;
      top: -1.3rem;
      transition: 0.1s;
    }

  @include media-breakpoint-up(sm) {
    margin-top: inherit;
    margin-bottom: inherit;
  }

  @include media-breakpoint-up(lg) {
    border: 3px solid $brand-primary;
    padding: 22px 42px;
    letter-spacing: 2px;
    font-size: 1rem;
      &:before {
        font-size: 3rem;
        left: -0.9rem;
        top: -1.65rem;
      }
  }
    &:after {
      content: '';
      background: #eeeeee;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 0%;
      height: 100%;
      transition: 0.1s;
    }
    &:hover {
      background-color: transparent;
      border-color: #c4dde6;
        &:before {
          color: #c4dde6;
          left: 96%;
          transition: 0.2s;
        }
        &:after {
          width: 100%;
          transition: 0.2s;
        }
    }

    &:focus {
      background-color: #fff !important;
      color: $brand-secondary;
      border-color: $brand-primary !important;
    }
}

.sep {
  width: 42px;
  height: 2px;
  display: block;
  margin: 1rem 0;
  margin-left: 0 !important;
  @include media-breakpoint-up(md) {
    margin: 2rem 0;
    height: 3px;
  }
  &:before {
    display: none;
  }
  &.blue {
      background-color: $brand-primary;
  }
  &.brown {
      background-color: $brand-secondary;
  }
}

section {
  a {
    color: #31454c;
    text-decoration: underline;
  }
}

strong {
  font-weight: 600;
  &.brown {
    color: $brand-secondary;
  }
}


.hamburger-eaten {
  overflow: hidden;
  body {
    background-color: $brand-primary;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  section, .main-content {
    display: none;
  }

}

#hamburger {
  @include media-breakpoint-up(md) {
    display: none;
  }
  display: block;
  position: absolute;
  right: 0.9375rem;
  top: 0.45rem;
  margin: 0;
  padding: 0;
  width: $hamburger-width;
  height: $hamburger-height;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background $hamburger-transistion-duration;
  background-color: transparent;
  z-index: 501;

  .hamburger-eaten & {
    //background-color: darken(rgb(255,50,100), 20%);
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    top: ($hamburger-height / 2) - ($bar-thickness / 2);
    left: $hamburger-pad;
    right: $hamburger-pad;
    height: $bar-thickness;
    background-color: #fff;

    .hamburger-eaten & {
      background: rgba(255, 255, 255, 0);
      transition: background 0s $hamburger-transistion-duration/2;
    }
    .hamburger-renew & {
      transition: background 0s $hamburger-transistion-duration/2;
    }

    &::before, &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: $bar-thickness;
      background-color: #fff;
      content: "";
      transition-duration: $hamburger-transistion-duration, $hamburger-transistion-duration;
      transition-delay: $hamburger-transistion-duration, 0s;
      .hamburger-eaten & {
        background-color: #fff;
        transition-delay: 0s, $hamburger-transistion-duration;
      }
    }
    &::before {
      top: -$bar-thickness - $hamburger-bar-space;
      transition-property: top, transform;
      .hamburger-eaten & {
        top: 0;
        transform: rotate(45deg);
      }
    }
    &::after {
      bottom: -$bar-thickness - $hamburger-bar-space;
      transition-property: bottom, transform;
      .hamburger-eaten & {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }

  }
}

.header {
  background-color: $brand-primary;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;


  .hamburger-eaten & {
    position: relative;
  }
  @include media-breakpoint-up(md) {
    padding: 2rem 0;
    position: relative;
  }
  .container {
    position: relative;
  }
    .logo {
      &:hover {
        text-decoration: none;
      }
      img {
        width: 160px;
        height: auto;
      }
      @include media-breakpoint-up(md) {
        img {
          width: 190px;
          padding: 0.8rem 0;
        }
      }
      @include media-breakpoint-up(lg) {
        img {
          width: 300px;
        }
      }
    }

    .navbar {
      padding: 0;
    }

    #navbarResponsive {
      display: none;
      .hamburger-eaten & {
        display: block !important;
      }
      @include media-breakpoint-up(md) {
        display: inline-block;
        float: right;
      }

        ul {
          margin-top: 3rem;
          @include media-breakpoint-up(md) {
            margin-top: 0.8rem;
          }
          @include media-breakpoint-up(lg) {
            margin-top: 1.6rem;
          }
          li {
            padding: 0.8rem 0 0.8rem 0.15rem;
            @include media-breakpoint-up(md) {
              padding: 0.8rem 1rem;
              display: inline-block;
            }
            @include media-breakpoint-up(lg) {
              padding: 1rem 1.5rem;
            }
            a {
              text-transform: uppercase;
              font-weight: 700;
              color: $brand-secondary;
              letter-spacing: 2px;
              position: relative;
              font-size: 1.3rem;
              @include media-breakpoint-up(md) {
                font-size: 0.85rem;
              }
              @include media-breakpoint-up(lg) {
                font-size: 0.95rem;
              }
              &:after {
                @include media-breakpoint-up(md) {
                  content: '';
                  width: 0px;
                  height: 3px;
                  background-color: #fff;
                  display: block;
                  position: absolute;
                  bottom: -5px;
                  left: 0px;
                  transition: 0.2s;
                }
              }
              &.active {
                color: #fff;
                  &:after {
                    @include media-breakpoint-up(md) {
                      content: '';
                      width: 30px;
                      height: 3px;
                      background-color: #fff;
                      display: block;
                      position: absolute;
                      bottom: -5px;
                      left: 0px;
                      transition: 0.2s;
                    }
                  }
              }
              &:hover {
                text-decoration: inherit;
                &:after {
                  @include media-breakpoint-up(md) {
                    content: '';
                    width: 30px;
                    height: 3px;
                    background-color: #fff;
                    display: block;
                    position: absolute;
                    bottom: -5px;
                    left: 0px;
                    transition: 0.2s;
                  }
                }
              }

              &:focus {
                text-decoration: none;
              }

              i {
                &.external {
                  background-image: url(../images/icons/icon-external-link.svg);
                  background-size: contain;
                  display: inline-block;
                  margin-left: 4px;
                  width: 12px;
                  height: 12px;
                }
              }
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
}

.main-content {
  padding-top: 66px;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.about {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-bottom: 150px;
  }
  .about-wrapper {
    figure {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      &.about-figure {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &:after {
          content: '+';
          font-weight: 700;
          font-size: 3rem;
          color: $brand-primary;
          position: absolute;
          right: -0.9rem;
          bottom: -2.5rem;
        }
      }
    }

    img {
      margin: 1.5rem 0;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
    a {
      &:hover {
        color: $text-color;
      }
    }
  }

  .text-right {
    .intro {
      font-size: 1.1rem;
      line-height: 1.5;
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 1.6;
      }
      a {
        color: $brand-secondary;
        font-weight: 600;
        transition: 0.2s;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: #ae9273;
          transition: 0.2s;
        }
      }
    }
  }
}


.appointment {
  padding: 2rem 0;
  @include media-breakpoint-up(md) {
    padding: 5rem 0;
  }

  .intro {
    font-size: 1.1rem;
    line-height: 1.5;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
      line-height: 1.6;
    }
  }

  .brown {
    color: $brand-secondary;
  }

  .btn {
    @include media-breakpoint-up(md) {
      margin-left: 60px;
    }
  }
}

.contact-title {
  background-color: $brand-primary;
  padding: 2rem 0;
  text-align: center;
  @include media-breakpoint-up(md) {
    padding: 5rem 0 4rem 0;
  }
  h2 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    letter-spacing: inherit;
    &:after {
      content: '';
      background-color: #fff;
      width: 40px;
      height: 2px;
      display: block;
      position: relative;
      top: 0.8rem;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3.8rem;
      letter-spacing: 10px;
      &:after {
        content: '';
        background-color: #fff;
        width: 60px;
        height: 3px;
        display: block;
        position: relative;
        top: 1rem;
        margin: 0 auto;
      }
    }
  }
}

.contact {
  background-color: $brand-primary;
  position: relative;
  border-bottom: 1rem $brand-secondary solid;
  padding-bottom: 60px;
  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 250px;
  }
  .contact-wrapper {
    figure {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      z-index: 0;
      margin-bottom: 0;
      &.about-figure {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .adress {
      font-size: 1.1rem;
      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }

    ul {
      padding: 0;
      li {
        list-style: none;
        padding-bottom: 0.2rem;
          a {
            text-decoration: inherit;
              &:hover {
                text-decoration: underline;
                color: $text-color;
              }
          }
      }
      &:after {
        content: '';
        background-color: $brand-secondary;
        width: 42px;
        height: 3px;
        display: block;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        @include media-breakpoint-up(md) {
          margin-top: 4rem;
        }
      }
    }
  }
}

.object-fit {
  &.compat-object-fit {
    background-size: cover;
    background-position: center center;
      img {
        opacity: 0;
      }
  }
}

.text-right {
  &.absolute {
    padding-top: 40px;
    @include media-breakpoint-up(md) {
      margin-left: 50%;
      padding: 60px 0 0 60px;
    }
  }
}

.therapeutiques {
  .sticky-nav {
    z-index: 99;
    position: relative;
    .stuck {
      position: fixed;
      top: 0;
      width: 100%;
    }
    nav {
      background-color: $brand-secondary;
      ul {
        padding: 1rem 0;
        margin-bottom: 0;
        border: 0;
        display: flex;

        li {
          flex-grow: 1;
          text-align: center;
          list-style: none;
          a {
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
            position: relative;
            font-size: 0.8rem;
            &:after {
              content: '';
              width: 0px;
              height: 3px;
              background-color: #fff;
              display: block;
              position: absolute;
              bottom: -5px;
              left: 0px;
              transition: 0.2s;
            }
            &:hover {
              text-decoration: inherit;
                &:after {
                  content: '';
                  width: 30px;
                  height: 3px;
                  background-color: #fff;
                  display: block;
                  position: absolute;
                  bottom: -5px;
                  left: 0px;
                  transition: 0.2s;
                }
            }
            &:focus {
              text-decoration: none;
            }
            &.active {
              color: $brand-primary;
                &:after {
                  width: 30px;
                  transition: 0.2s;
                }
            }
          }
        }
        @include media-breakpoint-up(xl) {
          padding: 1.6rem 0;
            li {
              a {
                letter-spacing: 2px;
                font-size: 0.95rem;
              }
            }
        }
      }
    }
  }

  h1 {
    color: $brand-secondary;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
      &:after {
        content: '';
        background-color: #fff;
        width: 40px;
        height: 2px;
        display: block;
        position: relative;
        top: 0.8rem;
        margin: 0 auto;
      }
    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
      letter-spacing: 8px;
      margin-top: 2rem;
      margin-bottom: 7rem;
        &:after {
          width: 60px;
          height: 3px;
          top: 1rem;
        }
    }
  }

  .content {
    &.blue {
      background-color: $brand-primary;
      section {
        padding: 2rem 0;
        @include media-breakpoint-up(md) {
          padding: 4rem 0;
        }

        h1 {
          @include media-breakpoint-down(md) {
            margin-bottom: 4rem;
          }
        }
          h2 {
            text-align: center;
            font-size: 1.6rem;
            letter-spacing: inherit;
            margin-top: 2rem;
            margin-bottom: 1rem;
              &:after {
                display: none;
              }
            @include media-breakpoint-up(lg) {
              font-size: 2.5rem;
              margin-top: 2rem;
              margin-bottom: 4rem;
            }
          }

          .intro {
            font-size: 1.1rem;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 2rem;
            @include media-breakpoint-up(md) {
              font-size: 1.1rem;
            }
            @include media-breakpoint-up(lg) {
              margin-bottom: 4rem;
              font-size: 1.25rem;
            }
          }
      }

      .block-border {
        padding: 2rem 1.5rem;
        position: relative;
        margin-top: 2rem;
        @include media-breakpoint-up(md) {
          padding: 6rem 4rem;
          margin-top: 4rem;
        }
        @include media-breakpoint-up(lg) {
          margin-top: 8rem;
        }
          .borders {
            &:before {
              content: '+';
              font-weight: 700;
              color: #fff;
              position: absolute;
              z-index: 11;
              font-size: 2.8rem;
              left: -0.7rem;
              top: -1.72rem;
              @include media-breakpoint-up(md) {
                font-size: 4.5rem;
                left: -1.15rem;
                top: -2.8rem;
              }
            }
            span {
              &.border {
                &:before {
                  content: '';
                  background: #fff;
                  display: block;
                  position: absolute;
                }
                &.top {
                  &:before {
                    width: 100%;
                    height: 2px;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-up(md) {
                      height: 4px;
                    }
                  }
                }

                &.bottom {
                  &:before {
                    width: 100%;
                    height: 2px;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    @include media-breakpoint-up(md) {
                      height: 4px;
                    }
                  }
                }

                &.left {
                  &:before {
                    width: 2px;
                    height: 100%;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-up(md) {
                      width: 4px;
                    }
                  }
                }

                &.right {
                  &:before {
                    width: 2px;
                    height: 100%;
                    z-index: 10;
                    top: 0;
                    right: 0;
                    @include media-breakpoint-up(md) {
                      width: 4px;
                    }
                  }
                }
              }
            }
          }

          .img {
            margin: 1.5rem 0;
            @include media-breakpoint-up(md) {
              margin: 0;
            }
          }

          &.img-left {
            .img {
              z-index: 1;
              position: relative;
              @media (min-width: 1040px) {
                margin-left: -6.2rem;
                margin-top: -9rem;
              }

              @media (min-width: 1500px) {
                margin-left: -14rem;
                margin-top: -9rem;
              }
            }
          }


          &.img-right {
            .img {
              z-index: 1;
              position: relative;
              @media (min-width: 1040px) {
                margin-right: -6.2rem;
                margin-top: -9rem;
              }
              @media (min-width: 1500px) {
                margin-right: -14rem;
                margin-top: -9rem;
              }
            }
          }
          ul {
            padding: 0;
            li {
              list-style: none;
              font-weight: 600;
              padding-bottom: 1.2rem;
              margin-bottom: 1.2rem;
              border-bottom: 1px solid #fff;
                &:last-child {
                  border-bottom: inherit;
                }
              @include media-breakpoint-up(md) {
                border-bottom: 2px solid #fff;
              }
            }
          }

        .top-infos {
          h3 {
            font-size: 1.1rem;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.4;
            letter-spacing: 0px;
            margin-left: 1.4rem;
            margin-top: 2rem;
            position: relative;
            &:before {
              content: '+';
              font-weight: 700;
              font-size: 1.8rem;
              color: #fff;
              position: absolute;
              left: -1.4rem;
              top: -0.4rem;
              transition: 0.1s;
            }
            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
              margin-left: 1.6rem;
                &:before {
                  top: -0.30rem;
                  font-size: 2.1rem;
                  left: -1.6rem;
                }
            }
            &:first-child {
              margin-top: 0;
            }
          }
          .icon {
            margin-top: 2.5rem;
            display: block;
          }
        }
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.4;
        letter-spacing: inherit;
        position: relative;
        &:before {
          content: '+';
          font-weight: 700;
          color: #fff;
          position: absolute;
          transition: 0.1s;
          font-size: 2rem;
          left: -0.8rem;
          top: -1.6rem;
        }
        .icon {
          margin-top: 0.2rem;
          margin-bottom: 2rem;
          display: block;
            &.syringue {
              img {
                width: 70px;
              }
            }

            &.recommandations {
              img {
                width: 38px;
              }
            }

            &.write {
              img {
                width: 40px;
              }
            }
        }
        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
          line-height: 1.3;
          letter-spacing: 3px;
          margin-top: 1rem;
          margin-bottom: 0;
          &:before {
            font-size: 2.4rem;
            left: -1.5rem;
            top: -2.2rem;
          }
        }
        @include media-breakpoint-up(lg) {
          font-size: 2rem;
          letter-spacing: 3px;
          margin-top: 1rem;
          margin-bottom: 0;
            &:before {
              font-size: 3rem;
              left: -2rem;
              top: -2.6rem;
            }
          .icon {
            img {
              width: auto !important;
            }
          }
        }
      }

      span {
        &.icon {
          &.syringue {
            img {
              width: 70px;
            }
          }

          &.write {
            img {
              width: 38px;
            }
          }
          img {
            @include media-breakpoint-up(lg) {
              width: auto !important;
            }
          }
        }
      }

      .centered-items {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
        }
        &.bottom-infos {
          padding-top: 4rem;

            .before-after {
              .subtitle {
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 0;
                  &:before {
                    display: none;
                  }
              }
              p {
                margin-left: 1.5rem;
                position: relative;
                  &:before {
                    content: '+';
                    font-weight: 700;
                    font-size: 1.8rem;
                    color: #fff;
                    position: absolute;
                    left: -1.5rem;
                    top: -0.55rem;
                    transition: 0.1s;
                  }
              }
            }
        }
      }

    }

    .appointment {
      background-color: #fff;
      border-bottom: 1rem #bfa68a solid;
      padding: 5em 0 4rem 0 !important;
      @include media-breakpoint-up(md) {
        padding: 6rem 0 !important;
      }
        .intro {
          text-align: left !important;
          margin-bottom: 1rem !important;
        }
    }
  }
}

.news {
  h1 {
    color: $brand-secondary;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    &:after {
      content: '';
      background-color: $brand-primary;
      width: 40px;
      height: 2px;
      display: block;
      position: relative;
      top: 0.8rem;
      margin: 0 auto;
    }
    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
      letter-spacing: 8px;
      margin-top: 6rem;
      margin-bottom: 7rem;
      &:after {
        width: 60px;
        height: 3px;
        top: 1rem;
      }
    }
  }


  .news-teaser {

    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid $brand-primary;
    @include media-breakpoint-up(lg) {
      margin-bottom: 4.5rem;
      padding-bottom: 4rem;
      border-bottom: 3px solid $brand-primary;
    }
    h2 {
      font-size: 1.6rem;
      color: $brand-secondary;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2rem;
      letter-spacing: 0px;
      margin-bottom: 1.5rem;
      position: relative;
      text-align: left;
      &:after {
        content: '';
        background-color: $brand-primary;
        width: 40px;
        height: 2px;
        display: block;
        position: relative;
        margin-left: 0;
        top: 0.7rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
        letter-spacing: inherit;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        letter-spacing: 8px;
          &:after {
            margin-top: 2rem;
            top: inherit;
            width: 60px;
            height: 3px;
          }
      }
    }

    .date {
      margin-bottom: 1rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
      }
      p {
          font-size: 0.8rem;
      }
    }
    &:last-child {
      border-bottom: 0;
    }

    .body {
      font-size: 0.95;
      @include media-breakpoint-up(lg) {
        font-size: 1.1rem;
      }
        a {
          color: $brand-secondary;
          font-weight: 600;
          transition: 0.2s;
            &:hover {
              text-decoration: none;
              color: #ae9273;
              transition: 0.2s;
            }
        }
    }
  }
}

.page-404 {
  .page-404-content {
    text-align: center;
    padding-bottom: 4rem;
    h1 {
      color: $brand-secondary;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 4rem;
      &:after {
        content: '';
        background-color: $brand-primary;
        width: 40px;
        height: 2px;
        display: block;
        position: relative;
        top: 0.8rem;
        margin: 0 auto;
      }
      @include media-breakpoint-up(lg) {
        font-size: 3.6rem;
        letter-spacing: 8px;
        margin-top: 6rem;
        margin-bottom: 7rem;
        &:after {
          width: 60px;
          height: 3px;
          top: 1rem;
        }
      }
    }

    h2 {
      font-size: 1.3rem;
      color: $brand-secondary;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0px;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      position: relative;
        &:after {
          display: none;
        }
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        letter-spacing: 1px;
      }
    }

    p {
      margin-bottom: 2.5rem;
    }
  }
}
