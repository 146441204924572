@font-face {
  font-family: 'din-bold-alternate';
  src: url('../fonts/DIN_bold_alternate.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIN_bold_alternate.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/DIN_bold_alternate.woff') format('woff'), /* Modern Browsers */
  url('../fonts/DIN_bold_alternate.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/DIN_bold_alternate.svg#baa81936f2538992dfe0ba3200e01ac1') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'din-medium-alternate';
  src: url('../fonts/DIN_medium_alternate.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIN_medium_alternate.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/DIN_medium_alternate.woff') format('woff'), /* Modern Browsers */
  url('../fonts/DIN_medium_alternate.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/DIN_medium_alternate.svg#baa81936f2538992dfe0ba3200e01ac1') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'din-bold';
  src: url('../fonts/DIN-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIN-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/DIN-bold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/DIN-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/DIN-bold.svg#f72eedb964480472218d30fa54520bd8') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'din-medium';
  src: url('../fonts/DIN-medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIN-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/DIN-medium.woff') format('woff'), /* Modern Browsers */
  url('../fonts/DIN-medium.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/DIN-medium.svg#43a856eecb2199e640a1a5bf1dc4a1e7') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'din-regular';
  src: url('../fonts/DIN-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIN-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/DIN-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/DIN-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/DIN-regular.svg#57984163b7a8df750038d0f2b70383f2') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}







