//$enable-flex: true;

$grey-dark:                 #373a3c !default;
$grey:                      #bababa !default;
$grey-light:                #f0f0f0 !default;
$grey-lighter:              #cacaca !default;
$grey-lightest:             #f5f5f5 !default;
$text-color:                #3f3f3f !default;

$brand-primary:             #d2e8f0;
$brand-secondary:           #bfa68a;
$brand-primary-dark:        #a7031b;
$brand-secondary-dark:      #467cad;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;

$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$link-color:                 $brand-primary;
$link-decoration:            none !default;
$link-hover-color:           darken($link-color, 15%);
$link-hover-decoration:      underline;

$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border-color:              #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-body:     	      "museo-sans", Arial, sans-serif;
$font-family-bold:     	      "din-bold", Arial, sans-serif;
$font-family-medium:     	  "din-medium", Arial, sans-serif;
$font-family-regular:     	  "din-regular", Arial, sans-serif;
$font-family-medium-alternate:  "din-medium-alternate", Arial, sans-serif;

// Size
$header-size:     10rem;
$bar-width:       5rem;
$bar-height:      0.4rem;
$bar-margin:      1rem;

$image-height:   200px;
$image-height-sm:   300px;
$image-height-md:   400px;
$image-height-lg:   500px;

//Hamburger
$hamburger-width: 35px;                    // The width of the button area
$hamburger-height: 25px;                   // The height of the button area
$bar-thickness: 3px;                    // The thickness of the button bars
$hamburger-pad: 0px;                      // The left/right padding between button area and bars.
$hamburger-bar-space: 6px;                // The spacing between button bars
$hamburger-transistion-duration: 0.2s;     // The transition duration